import React, { Fragment, Component } from "react";
import styled from "styled-components";
import PHLogo from "./ProductHuntLogo";

import "../css/custom.css";

const Banner = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100vw !important;
  height: auto;
  padding: 1rem;

  line-height: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;

  @media (max-width: 560px) {
    height: 150px;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  ${"" /* background-color: #232855;
  background-color: #212121; */}
  background-image: linear-gradient(120deg, #ee0979, #ff6a00 100%);
`;

const ButtonCTA = styled.button`
  padding: 0 14px;
  height: 30px;
  font-size: 14px;
  color: #212121;
  border-style: none;
  font-weight: bold;
  line-height: 30px;
  text-shadow: #fff 0px 1px 1px;
  margin-left: 15px;
  white-space: nowrap;
  text-transform: capitalize;
  border-radius: 4px;
  margin-bottom: 15px !important;
  background: #94f79c;
  box-shadow: 0 6px 13px 0 rgba(0, 0, 0, 0.15);
  @media (max-width: 700px) {
    margin-left: 0px;
    margin-top: 10px;
  }
`;

const Notice = styled.span`
  color: #da552f;
  font-weight: 700;
  background-image: linear-gradient(120deg, #da552f, #da552f 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.1em;
  background-position: 0 100%;
  transition: background-size 0.25s ease-in;
  &:hover {
    background-size: 100% 88% !important;
    color: white;
  }
`;

export const Header = (props) => {
  const position = props.relative ? { position: "relative" } : null;

  return (
    <Fragment>
      <Banner style={{ ...position }}>
        <div className="row" style={{ display: "flex", alignItems: "center" }}>
          <div className="col-md-9 col-xs-12">
            {/* We just launched on <PHLogo style={{ margin: "0 .5rem" }} />  
            offer you <Notice>10 free participants</Notice> to kickstart your
            first Eye Tracking experience! 🎉
            */}
            We are live on Product Hunt !!! Sign up now and get 10 free
            participants! 🎉
          </div>
          {/* <ButtonCTA>Support us on Product Hunt</ButtonCTA> */}
          <div className="col-md-3 col-xs-12" style={{ margin: "0.5rem 0 " }}>
            <a
              href="https://www.producthunt.com/posts/wordpress2gatsby"
              target="_blank"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=142363&theme=light"
                alt="Wordpress2Gatsby - A simple CLI tool  to migrate from Wordpress to Gatsby. | Product Hunt Embed"
                style={{
                  width: "250px",
                  height: "40px",
                  zIndex: "10000",
                  margin: "0",
                }}
              />
            </a>
          </div>
        </div>
      </Banner>
    </Fragment>
  );
};

export default Header;
