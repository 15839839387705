import React, { Fragment, Component } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import AnchorLink from "react-anchor-link-smooth-scroll";
import { FiTwitter, FiLinkedin, FiFacebook, FiLink } from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";

import LogoDark from "../assets/logo-dark.svg";
import LogoLight from "../assets/logo-light.svg";
import Banner from "../components/Banner";

import "../css/custom.css";
import NeuronsBanner from "./NeuronsBanner";

const StyledLink = styled(Link)`
  padding-left: 0.5em;
  padding-right: 0.5em;
`;

const StyledAnchor = styled(AnchorLink)`
  padding-left: 0.5em;
  padding-right: 0.5em;
`;

class Header extends Component {
  state = {
    hamburger: "hidden-xs",
    isFixed: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    if (this.header === undefined) return;

    const scrollY = window.scrollY;
    const isFixed = scrollY > this.header.offsetHeight;
    this.setState({ isFixed });
  };

  handleHamburger = () => {
    const { hamburger } = this.state;
    const newHamburger = hamburger ? "" : "hidden-xs ";
    this.setState({ hamburger: newHamburger });
  };

  render() {
    const { isFixed, hamburger } = this.state;
    const { isLanding } = this.props;

    const burgerMenu = `
      bar 
      bar--sm 
      bar-1 
      ${hamburger} 
      ${isFixed ? "pos-fixed" : ""}
      bar--absolute
    `;

    return (
      <Fragment>
        {/* <div className="hidden-md hidden-lg">
          <Banner relative />
        </div> */}
        <NeuronsBanner />
        <div className="nav-container">
          <div className="bar bar--sm visible-xs pos-fixed">
            <div className="container">
              <div className="row">
                <div className="col-3 col-md-2">
                  <Link to="/">
                    <img
                      className="logo logo-dark fix-logo"
                      alt="logo"
                      src={LogoDark}
                    />
                  </Link>
                </div>
                <div className="col-9 col-md-10 text-right">
                  <GiHamburgerMenu
                    onClick={() => this.handleHamburger()}
                    style={{ fill: "black" }}
                    size="1.5em"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ref={(ref) => (this.header = ref)}>
          <nav id="menu1" className={burgerMenu}>
            <div className="container">
              {/* <div className="hidden-xs">
                <Banner />
              </div> */}
              {/* Custom Style for the Hunt Banner */}
              <div
                className="row"
                // style={{ position: "relative", marginTop: "72px" }}
              >
                <div className="col-lg-3 col-md-4 hidden-xs">
                  <div className="bar__module">
                    <Link to="/">
                      <img
                        className="logo logo-dark fix-logo"
                        alt="logo"
                        src={LogoDark}
                      />
                    </Link>
                  </div>
                  {/*end module*/}
                </div>
                <div className="col-lg-9 col-md-12 text-right text-left-xs text-left-sm">
                  <div className="bar__module">
                    <ul className="menu-horizontal text-left">
                      <li>
                        <StyledLink to="/"> Home </StyledLink>
                      </li>
                      {/* <li>
                        {isLanding ? (
                          <StyledAnchor href="/#how" offset={`${160 * 2}`}>
                            How It Works
                          </StyledAnchor>
                        ) : (
                          <StyledLink to="/how"> How It Works </StyledLink>
                        )}
                      </li> */}
                      <li>
                        <StyledLink to="/how"> How It Works </StyledLink>
                      </li>
                      <li id="pricing">
                        <StyledLink to="/pricing"> Pricing </StyledLink>
                      </li>
                      <li>
                        <StyledLink to="/help"> Help </StyledLink>
                      </li>
                      <li id="ContactUs">
                        <StyledLink to="/contact"> Contact Us </StyledLink>
                      </li>
                    </ul>
                  </div>
                  {/*end module*/}
                  <div className="bar__module">
                    <a
                      className="btn btn--sm type--uppercase"
                      href="https://app.loceye.io/auth/login"
                      style={{ borderWidth: "2px", borderColor: "#212121" }}
                    >
                      <span className="btn__text" id="signin">
                        {" "}
                        Sign In{" "}
                      </span>
                    </a>
                    <a
                      className="btn btn--sm btn--primary type--uppercase"
                      href="https://app.loceye.io/auth/signup"
                    >
                      <span className="btn__text " id="getstarted">
                        Get Started
                      </span>
                    </a>
                  </div>
                  {/*end module*/}
                </div>
              </div>
              {/*end of row*/}
            </div>
            {/*end of container*/}
          </nav>
        </div>
      </Fragment>
    );
  }
}

export default Header;
