import React from "react";
import styled from "styled-components";

import Header from "./Header";
import Footer from "./Footer";
import Banner from "./FloatingBanner";

import drift from "../utils/drift";
import { backgroundColor } from "../utils/colors";
import { maxDevice } from "../utils/media-queries";

import "../css/custom.css";
import "../css/theme.css";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${backgroundColor};
  z-index: -10;
  min-height: 100vh;
`;

const SpaceCancelled = styled.div`
  padding-bottom: 8em;

  @media ${maxDevice.laptop} {
    padding-bottom: 0;
  }
`;

class Layout extends React.Component {
  state = {
    isOpen: true,
  };

  componentDidMount() {
    //drift();
  }

  closeBanner = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { isOpen } = this.state;
    const { location, title, children } = this.props;

    const isLandingPage = location.pathname === "/";

    return (
      <Wrapper>
        {/* {isOpen && <Banner closeBanner={this.closeBanner} />} */}
        <Header isLanding={isLandingPage} />
        {!isLandingPage && <SpaceCancelled />}
        {children}
        <Footer />
      </Wrapper>
    );
  }
}

export default Layout;
