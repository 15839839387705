import React from "react";
import { FiTwitter, FiLinkedin, FiFacebook, FiLink } from "react-icons/fi";
import { Link } from "gatsby";

import "../css/theme.css";
import "../css/custom.css";
import LogoLight from "../assets/logo-light.svg";
// import '../css/socicon.css';

const Footer = props => {
  return (
    <footer className="text-center-xs space--xs bg--dark footer">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <ul className="list-inline">
              <li>
                <Link to="/about">
                  <span className="h6">About</span>
                </Link>
              </li>

              <li>
                <Link to="/careers">
                  <span className="h6">Careers</span>
                </Link>
              </li>
              <li>
                <Link to="/pricing">
                  <span className="h6" id="pricingfooter">
                    Pricing
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/contact">
                  <span className="h6" id="contactusfooter">
                    Contact us
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/help">
                  <span className="h6">Help</span>
                </Link>
              </li>
              <li>
                <a href="https://www.visualeyes.design?ref=loceye.io">
                  <span className="h6">AI Design Assistant</span>
                </a>
              </li>
            </ul>
          </div>
          <div
            className="col-sm-12 col-md-4 text-right-theme text-center-sm text-center-xs"
            style={{ margin: "auto" }}
          >
            <ul
              className="social-list list-inline list--hover"
              style={{ marginBottom: "3em" }}
            >
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/loceye.io/"
                >
                  <FiFacebook size="1.5em" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/Loceye_io"
                >
                  <FiTwitter size="1.5em" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/loceye/"
                >
                  <FiLinkedin size="1.5em" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/*end of row*/}

        <div className="row">
          <div className="col-md-8">
            <div className="row footer-fix">
              <img className="footer-logo" alt="logo" src={LogoLight} />
            </div>
            <div className="row footer-fix">
              <span className="type--fine-print ">
                ©
                <span className="update-year" /> Loceye Private Company
              </span>
            </div>
            {/* <a className="type--fine-print" href="#">
              Privacy Policy
            </a>
            <a className="type--fine-print" href="#">
              Legal
            </a> */}
          </div>
          <div className="col-sm-12 col-md-4 text-right-theme text-center-sm text-center-xs">
            <a className="type--fine-print" href="mailto:info@loceye.io">
              info@loceye.io
            </a>
          </div>
        </div>
        {/*end of row*/}

        <div className="row">
          <div className="col-md-8">
            <span className="type--fine-print ">
              <Link to="/terms">Terms and Conditions</Link>
            </span>
          </div>
        </div>
        {/*end of row*/}
      </div>
      {/*end of container*/}
    </footer>
  );
};

export default Footer;
