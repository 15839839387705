import React from "react";
import PropTypes from "prop-types";

const ProductHuntLogo = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.3726 24 0 18.6274 0 12C0 5.3726 5.3726 0 12 0C18.6274 0 24 5.3726 24 12Z"
        fill="#DA552F"
      />
      <path
        d="M13.6 6H7.79999V18H10.2V14.4001H13.6V14.4C15.9196 14.4 17.8 12.5195 17.8 10.2C17.8 7.8805 15.9196 6 13.6 6ZM13.6 12V12.0001H10.2V8.4H13.6C14.5941 8.4 15.4 9.2059 15.4 10.2C15.4 11.1941 14.5941 12 13.6 12Z"
        fill="white"
      />
    </svg>
  );
};

export default ProductHuntLogo;
