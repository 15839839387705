import React, { Fragment, Component } from "react";
import styled, { keyframes } from "styled-components";
import PHLogo from "./ProductHuntLogo";

import "../css/custom.css";

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
`;

const intro = keyframes`
	0% {
		transform: translateX(-300%);
	}

	16% {
		transform: translateX(32.27%);
	}

	28% {
		transform: translateX(-13.12%);
	}

	44% {
		transform: translateX(4.63%);
	}

	59% {
		transform: translateX(1.64%);
	}

	73% {
		transform: translateX(-0.58%);
	}

	88% {
		transform: translateX(-0.2%);
	}

	100% {
		transform: translateX(0%);
	}
`;

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;

  padding: 1rem;
  margin: 1rem;

  border-radius: 8px;

  line-height: 20px;
  text-align: center;
  font-size: 14px;
  ${"" /* font-weight: bold; */}

  @media (max-width: 560px) {
    height: 150px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  color: #fff;
  background-color: #ea532b;
  background-image: linear-gradient(120deg, #ee0979, #ff6a00 100%);

  transform: translateX(-300%);

  animation: ${intro} 1.5s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
`;

const ButtonCTA = styled.button`
  padding: 0 14px;
  height: 30px;
  font-size: 14px;
  color: #212121;
  border-style: none;
  font-weight: bold;
  line-height: 30px;
  text-shadow: #fff 0px 1px 1px;
  margin-left: 15px;
  white-space: nowrap;
  text-transform: capitalize;
  border-radius: 4px;
  margin-bottom: 15px !important;
  background: #94f79c;
  box-shadow: 0 6px 13px 0 rgba(0, 0, 0, 0.15);
  @media (max-width: 700px) {
    margin-left: 0px;
    margin-top: 10px;
  }
`;

const Notice = styled.span`
  color: #da552f;
  font-weight: 700;
  background-image: linear-gradient(120deg, #da552f, #da552f 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.1em;
  background-position: 0 100%;
  transition: background-size 0.25s ease-in;
  &:hover {
    background-size: 100% 88% !important;
    color: white;
  }
`;

export const Banner = (props) => {
  const position = props.relative ? { position: "relative" } : null;

  return (
    <Container style={{ ...position }}>
      <Header>
        <div style={{ marginBottom: "1.5rem", marginRight: "1rem" }}>
          <p style={{ lineHeight: "1.5" }}>
            We are live on Product Hunt!!! <br />
            Sign up now and get <b style={{ fontSize: "1.5rem" }}>10</b> free
            participants! 🎉
          </p>
        </div>
        <div>
          <p onClick={props.closeBanner} style={{ cursor: "pointer" }}>
            x
          </p>
        </div>
      </Header>
      <div>
        <a
          href="https://www.producthunt.com/posts/wordpress2gatsby"
          target="_blank"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=142363&theme=light"
            alt="Wordpress2Gatsby - A simple CLI tool  to migrate from Wordpress to Gatsby. | Product Hunt Embed"
            style={{
              width: "250px",
              height: "40px",
              zIndex: "10000",
              margin: "0",
            }}
          />
        </a>
      </div>
      <div />
    </Container>
  );
};

export default Banner;
